export const environment = {
  production: false,
  authority: 'www.test.salesforce.com',
  redirectUrl: 'https://sit.selfservice.mypage.manulife.co.jp',
  authWellknownEndpointUrl: 'https://mljsit-cws-ohana.manulife.co.jp',
  clientId: '3MVG9Gdzj3taRxuMATa3R8PqUPIksbH3R8PNEhSqUTUoGz1sl4kxyQDE4lgcIajWXA4Yyr32t_ohiEYeRbZFu',
  scope: 'openid api',
  responseType: 'code',
};

export const common_urls = {
  // bffBaseUrl: 'https://internal.sit.apps.manulife.co.jp/ext/cws/bff/api/v1',
  // bffBaseUrl: 'https://jp-sit1-api.ap.manulife.com/ext/cws/bff/api/v1',
  bffBaseUrl: 'https://sit.apps.manulife.co.jp/ext/cws/bff/api/v1',
  cwsBaseUrl: 'https://mljsit-cws-ohana.manulife.co.jp',
  surveyUrl: '' // not in use
}
export const common_url_sur = {
  bffBaseSurUrl: 'https://sit.apps.manulife.co.jp/ext/cws-sur/bff/api/v1/',
  cwsLoginUrl: "https://mljsit-cws-ohana.manulife.co.jp/?cws=1",
  publicKey: 'l1GqWVYsA/sdYvAruN2WLSvx/Z4LvgjgcjhgJEtIWOE='
}

export const stp_cws_urls = {
  baseUrl: 'https://sit.apps.manulife.co.jp/ext/cws-sur/bff/api/v1',
  cwsLoginUrl: "https://mljsit-cws-ohana.manulife.co.jp/auth",
  publicKey: 'l1GqWVYsA/sdYvAruN2WLSvx/Z4LvgjgcjhgJEtIWOE=',
  bffBaseUrl: 'https://sit.apps.manulife.co.jp/ext/cws/bff/stp/api/v1',
  oodMenuItemsUrl: 'https://sit.apps.manulife.co.jp/ext/cws/bff/api/v1/'
}
