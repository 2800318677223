export const SFDCNavigationEventUrlMapping: { [key: string]: string } = {
  "changeAddressPhoneNumberSubItem": "addresschange",
  "cancellationSubItem": "WMSurrenderNotes",
  "insurancePolicyReissueSubItem": "PolicyReIssue",
  "issuanceLifeInsurancePremiumDeductionCertificateSubItem": "taxreissue",
  "changeNamePolicyholderInsuredInvoiceOrderSubItem": "FamilyNameChange",
  "changeNameBeneficiaryDesignatedProxyClaimantContractorInvoiceOrderSubItem": "RightHolderNameChange",
  "emailInquiry":"EmailInquiry",
  "membershipUpgrade":"Membership_Upgrade",
  "enterUpgradePassword":"EnterUpgradePassword",
  "tDSurrender1" : "TDSurrender_1",
  "wMSurrenderNotes" : "WMSurrenderNotes",
  "policyReissue":"PolicyReIssue",
  "changeTransferAccountSubItem":"bankAccountChangePrecautions",
  "creditCardChangeSubItem":"MLJ_CWS_CreditCardChange_Notes",
  "useOfPolicyholderLoansSubItem":"TD_LOAN"
}

// event-url mapping for static urls across all environments
export const SFDCDirectNavigationEventUrlMapping: { [key: string]: string } = {
  "benefitProcedureItem": "http://www.manulife.co.jp/rakuraku-seikyu",
  "faqItem": "https://manulife.my.site.com/faq/s/",
  "medicalReliefSubItem":"https://plus-baton.t-pec.co.jp/manulife/lp?utm_source=ml&utm_medium=w&utm_campaign=2309mlwe"
}

// event-url mapping for environment-based urls
export const SFDCDirectNavigationEventEnvBasedUrlMapping: { [key: string]: string } = {
  "listOfContractsItem": "home?from=auth&cws=1",
  "contractDocumentsItem": "DownloadPage?from=auth&cws=1",
  "inquiryItem": "emailinquiry?from=",
  "customerInfoConfirmationSubItem":"RegistrationInfoManagement?cws=1",
  "idChangeSubItem":"EmailAddressChangeRequest?cancelret=home&cws=1",
  "passwordChangeSubItem":"Change_Password?from=home&cws=1"
}
