import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  isAuthenticated!: boolean;
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private httpClient: HttpClient
  ) {}

  /* Logout user from OIDC */
  OIDClogout(url: string) {
    console.log('Entered Logout');

    this.verifyAuthentication();
    this.verifyToken();
    this.logoutUser(url);
  }

  /* function to check OIDC Authentication */
  verifyAuthentication(): void {
    this.oidcSecurityService.isAuthenticated$.subscribe(
      ({ isAuthenticated }) => {
        this.isAuthenticated = isAuthenticated;

        console.warn('authenticated: ', isAuthenticated);
      }
    );
  }

  /* function to check token */
  verifyToken(): void {
    this.oidcSecurityService.getIdToken().subscribe((idToken: string) => {
      console.log('id token', idToken);
    });
  }

  /* function to logout user from OIDC */
  logoutUser(url: string): void {
    this.oidcSecurityService.logoff().subscribe((result) => {
        console.log('logoff result', result);
        this.redirectToCWS(url);
    })
  }

  /* function to redirect user to cws page after logout */
  redirectToCWS(url: string): void {
    // window.location.href = url;
    window.open(url, '_self');
  }

  // /* CWS call on survey link open */
  // cwsSurveyCall(url: string, obj: { userid: string }): Observable<any> {
  //   const cwsUrl: string = `${url}/services/apexrest/user/`;
  //   return this.oidcSecurityService.getAccessToken().pipe(
  //     switchMap((accessToken) => {
  //       const requestOptions = {
  //         headers: new HttpHeaders({
  //           Authorization: 'Bearer ' + accessToken,
  //         }),
  //       };

  //       return this.httpClient.post(cwsUrl, obj, requestOptions);
  //     })
  //   );
  // }

  // /* Function to logoff users locally from OIDC */
  // logoffLocal() {
  //   console.log('local logoff called');
  //   this.oidcSecurityService.logoffLocal();
  // }
}
