/**
 * This enum is defining the error mapping for FAQ modal. FAQ modal have 4 common errors, with same text but different error codes
 */
export enum ErrorFaqModalMsgs {
  // Change name error FAQ
  CWSDIS001 = "app.common-modal.change-name.text-1", // Error text with Error code 1
  CWSDIS002 = "app.common-modal.change-name.text-2", // Error text with Error code 2
  CWSDIS003 = "app.common-modal.change-name.text-3", // Error text with Error code 3
  CWSDIS004 = "app.common-modal.change-name.text-4", // Error text with Error code 4
  CWSTDSB001 = "app.common-modal.change-name.text-1", // Error text with Error code 1
  CWSRWS001  = "app.common-modal.change-name.text-1", // Error text with Error code 1
  CWSRWS002 = "app.common-modal.change-name.text-2", // Error text with Error code 2
  // Cancellation Error FAQ
  CWSCAN001 = "app.common-modal.change-name.text-1", // Error text with Error code 1
  CWSCAN002 = "app.common-modal.change-name.text-2", // Error text with Error code 2
  // Reissue Error FAQ
  CWSREI001 = "app.common-modal.change-name.text-1", // Error text with Error code 1
  CWSREI002 = "app.common-modal.change-name.text-2",  // Error text with Error code 2
  CWSREI008 = "app.common-modal.change-name.text-1", // Error text with Error code 1
  CWSREI009 = "app.common-modal.change-name.text-2",  // Error text with Error code 2
  CWSREI010 = "app.common-modal.change-name.text-1-2",  // Error text with Error code 1,Error code 2
   //Procedure Premium Insurance FAQ Error
   CWSBAC001 = "app.common-modal.change-name.text-1", // Error text with Error code 1
   CWSBAC002 = "app.common-modal.change-name.text-2",  // Error text with Error code 2
   CWSCCC001 = "app.common-modal.change-name.text-1", // Error text with Error code 1
   CWSURB001 = "app.common-modal.change-name.text-1", // Error text with Error code 1
   CWSURB002 = "app.common-modal.change-name.text-2",  // Error text with Error code 2
   CWSURB003 = "app.common-modal.change-name.text-3", // Error text with Error code 3
   CWSURB004 = "app.common-modal.change-name.text-4", // Error text with Error code 4
   CWSURB005 = "app.common-modal.change-name.text-7",  // Error text with Error code 5
   CWSURB006 = "app.common-modal.change-name.text-8", // Error text with Error code 6
   CWSURB007 = "app.common-modal.change-name.text-9", // Error text with Error code 7

   CWSUPL001 = "app.common-modal.change-name.text-1", // Error text with Error code 1
   CWSUPL005 = "app.common-modal.change-name.text-7",  // Error text with Error code 5
   CWSUPL006 = "app.common-modal.change-name.text-8", // Error text with Error code 6

};
/**
 * This enum is defining the error mapping for Progress modal.
 */
export enum ProgressModalMsgs{
  CWSCAN004 = "app.common-modal.cancellation.text-3", // Progress Model error msg for cancellation
  CWSTDSB003 = "app.common-modal.cancellation.text-3", // Progress Model error msg for cancellation bill request
  CWSRWS004 = "app.common-modal.cancellation.text-3",// Progress Model error msg for cancellation dynamic menu
  CWSCCC003 = "app.common-modal.cancellation.text-3",
  CWSURB009 = "app.common-modal.cancellation.text-3", 
  CWSUPL003 = "app.common-modal.cancellation.text-3",// Progress Model error msg for Use Of Policyholder Loans
};
