import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, NavigationEnd, Router } from '@angular/router';
import { BasicModalService, FetchMenuService, LayoutDataModel, ModalConfig, SideNavConfigItem } from '@cws-ui/ngx-cws-app-layout';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, catchError, of, switchMap } from 'rxjs';
import { common_urls, stp_cws_urls } from 'src/environments/environment';
import { LoaderService } from './service/loader.service';
import { StpLayoutData, StpScreenType } from './shared/side-nav-config/stp.config';
import { create_UUID } from './utils/commons';
import { SFDCDirectNavigationEventEnvBasedUrlMapping, SFDCDirectNavigationEventUrlMapping } from './shared/side-menu-popup/side-menu-constants';
import { CwsUrlNavigator } from './shared/cws-url-navigator';
import { ChangeContractInformation, SideMenuItems } from './shared/side-menu-popup/enums/side-menu-items.enums';
import { ErrorFaqModalMsgs } from './shared/side-menu-popup/enums/error-faq-modal-msgs.enum';
import { SideMenuPopupService } from './shared/side-menu-popup/side-menu-popup.service';
import { MatDialog } from '@angular/material/dialog';
import { ReissueErrorCodes } from './shared/side-menu-popup/enums/side-menu-error-codes.enum';
import { EtaxLayoutData } from './shared/side-nav-config/etax.config';
import { CwsTitleService } from './service/cws-title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isCWSPage: boolean = false;
  isAuth: boolean = false;
  sideNavAllowedRoutes: string[] = [
    '/policy-download',
  ]

  showLogoutButton: boolean = false;

  // dynamic config for side nav
  layoutData!: LayoutDataModel;

  screenType!: string;
  errorFaqModalMsgText: string = "";
  progressModalText: string = "";
  id: string = "";
  popupModalSubscribe!: Subscription;
  @ViewChild("showFamilyNameMsg") showFamilyNameMsg!: TemplateRef<any>;
  @ViewChild("addressChange") addressChange!: TemplateRef<any>;
  @ViewChild("errorFaqModal") errorFaqModal!: TemplateRef<any>;
  @ViewChild("progressModal") progressModal!: TemplateRef<any>;
  @ViewChild("taxCertificate") taxCertificate!: TemplateRef<any>;
  constructor(private translateService: TranslateService,
    private modalService: BasicModalService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private oidcSecurityService: OidcSecurityService,
    private fetchMenuService: FetchMenuService,
    private httpClient: HttpClient,
    private sideMenuPopupService: SideMenuPopupService,
    private dialog: MatDialog,
    private cwsTitleService: CwsTitleService) {
    this.translateService.setDefaultLang('ja');
    this.translateService.use('ja');
  }

  ngOnInit() {
    console.log('nfr validations deployed');
    // on route navigation getting the query param for fetching internal data
    this.route.queryParamMap.subscribe((res: any) => {
      let policyNumber: string, smallProdCD: string;
      let params = res['params'];
      CwsUrlNavigator.policyNumber = policyNumber = params['polNo'] ? params['polNo'] : "";//this.gasaService.policyNumber;
      if (params['sprodCd']) {
        smallProdCD = params['sprodCd'];
      }
      else if (params['sprodCode']) {
        smallProdCD = params['sprodCode'];
      }
      else {
        smallProdCD = "";//this.gasaService.smallProdCD || '';
      }
      CwsUrlNavigator.sProdCd = smallProdCD;
      CwsUrlNavigator.id = this.id = params["polId"] ? params['polId'] : "";// this.gasaService.id;
    });

    this.cwsTitleService.startMonitoring();

    this.router.events.subscribe((evt: any) => {
      if ((evt instanceof NavigationEnd)) {
        if (evt.url.includes('/cws-registration/landing-page')) {
          this.isCWSPage = true;
        } else {

          this.isCWSPage = false;

          //setting side menu config
          this.setSideMenuConfig();

          //checking if we need to display the side menu
          const isAllowed = this.sideNavAllowedRoutes.some(sideNaveRoute => {
            const urlItems = evt.url.split("?")
            return urlItems[0] === sideNaveRoute && this.isAuth
          });

          if (isAllowed) this.fetchSideMenu();

        }
      }

      if (evt instanceof GuardsCheckEnd) {
        this.isAuth = evt.shouldActivate
      }

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  /**
 * set side menu confiugration dynamically as per projects requirements
 */
  setSideMenuConfig() {
    if (this.router.url.includes("/policy-download")) {
      this.showLogoutButton = true;
      this.layoutData = StpLayoutData;
      this.screenType = StpScreenType;
    } else if (this.router.url.includes("/stp-cws-registration")) {
      this.showLogoutButton = false;
      this.layoutData = StpLayoutData;
    } else if (this.router.url.includes("/etax/")) {
      this.layoutData = EtaxLayoutData;
    }

    CwsUrlNavigator.screenType = this.screenType;
  }

  handleMenuClick(event: any) {
    // Static Navigation to other URL specified in SFDCDirectNavigationEventUrlMapping
    if (Object.keys(SFDCDirectNavigationEventUrlMapping).includes(event.target)) {
      const navigationUrl = SFDCDirectNavigationEventUrlMapping[event.target];
      window.open(navigationUrl, "_blank");
    }
    // Static SFDC env specific Navigation 
    else if (Object.keys(SFDCDirectNavigationEventEnvBasedUrlMapping).includes(event.target)) {
      const baseUrl = common_urls.cwsBaseUrl;
      const subPath = SFDCDirectNavigationEventEnvBasedUrlMapping[event.target];
      if (event.target == "inquiryItem") {
        // in case of STP , we only need to pass from=home param
        if (this.screenType == StpScreenType) {
          window.open(`${baseUrl}/${subPath}home`, "_self");
        }
        else {
          window.open(`${baseUrl}/${subPath}policyinquiry&id=${this.id}`, "_self");
        }
      }
      else {
        window.open(`${baseUrl}/${subPath}`, "_self");
      }
    }
    // C360 internal navigation on menu click event
    else if (event.target == "rakuRakuFamilyRegistrationSubItem") {
      const polNo = CwsUrlNavigator.policyNumber || '';
      const sprodCd = CwsUrlNavigator.sProdCd || '';
      const lprodCd = CwsUrlNavigator.lProdCd || '';
      const id = CwsUrlNavigator.id || '';
      const queryParam = { "sprodCd": sprodCd, "lprodCd": lprodCd, "polNo": polNo, "id": id };
      this.redirectToURL("family-registration/home", true, queryParam);
    }
    // Menu event handling.
    else {
      const subMenu = this.fetchMenuService.findSideNavItem(this.layoutData.leftMenuItems.leftMenuItems, event.target);
      if (subMenu)
        // event.parent is referencing parent of clicked submenu.
        switch (event.parent) {
          case SideMenuItems.ChangeContractInformation:
            this.showContractInformation(event.target, subMenu);
            break;
          case SideMenuItems.ReissueProcedure:
            this.showReIssueProcedure(event.target, subMenu);
            break;
        }
    }

  }

  /**
* Following method is related to show popup or navigate as per provided  business logic for all sub-menus of contract information changes and should not be removed.
* @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
* @param subMenu : This param hold all the information and properties for the clicked submenu item.
*/
  showContractInformation(sideMenuItem: string, subMenu: SideNavConfigItem) {
    // As per the business logic, this method is returning in-case of popup : pop-up config with redirection url ,if not then redirection url.
    const [config, redirectUrl] = this.sideMenuPopupService.changeContractInformation(sideMenuItem, subMenu, this.errorFaqModal, this.showFamilyNameMsg, this.addressChange)
    if (!config) {
      if (redirectUrl)
        this.redirectToURL(redirectUrl)
    }
    else {
      if (sideMenuItem == ChangeContractInformation.ChangeNameBeneficiaryDesignatedProxyClaimantContractorInvoiceOrder && subMenu.metadata) {
        this.errorFaqModalMsgText = ErrorFaqModalMsgs[subMenu.metadata["displayRightHolderMsg"] as keyof typeof ErrorFaqModalMsgs];
      }
      this.popupModalSubscribe = this.showPopUp(config).subscribe(result => {
        if (result == "true") {
          if (redirectUrl) {
            this.redirectToURL(redirectUrl)
          }
        }
        // else , popup will be closed with no action
      });
    }
  }

  /**
   * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of ReIssue Procedure and should not be removed.
   * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
   * @param subMenu : This param hold all the information and properties for the clicked submenu item.
   */
  showReIssueProcedure(sideMenuItem: string, subMenu: SideNavConfigItem) {
    // As per the business logic, this method is returning in-case of popup : pop-up config with redirection url ,if not then redirection url.
    const [config, redirectUrl, isInternalNavigation, queryParam] = this.sideMenuPopupService.reissueProcedure(sideMenuItem, subMenu, this.errorFaqModal, this.progressModal, this.taxCertificate);

    if (!config) {
      if (redirectUrl)
        this.redirectToURL(redirectUrl, isInternalNavigation, queryParam);
    }
    else {
      if (subMenu.metadata) {
        if (subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI001 || subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI002
          || subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI008 || subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI009
          || subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI010) {
          this.errorFaqModalMsgText = ErrorFaqModalMsgs[subMenu.metadata["popupActionCode"] as keyof typeof ErrorFaqModalMsgs];;
        }
        if (subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI003 || subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI004) {
          this.progressModalText = subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI003 ? "" : "app.common-modal.cancellation.text-3";
        }
        this.popupModalSubscribe = this.showPopUp(config).subscribe(result => {
          if (result == "true") {
            if (redirectUrl) {
              this.redirectToURL(redirectUrl);
            }
          }
          // else , popup will be closed with no action
        });
      }
    }

  }

  showPopUp(popUpConfig: ModalConfig): Observable<any> {
    this.dialog.closeAll();
    return this.modalService.openModal(popUpConfig);
  }

  private fetchSideMenu(policyNumber?: string, smallProdCD?: string) {
    let headers: HttpHeaders;
    const screenType = this.screenType;
    this.loaderService.show();
    let url: string;
    //URL to be updated dynamically
    if (this.screenType === StpScreenType) {
      url = `${stp_cws_urls.oodMenuItemsUrl}/menu-items/${create_UUID()}?screenType=${screenType}`;
    } else {
      // url = `${common_urls_gasa.oodMenuItemsUrl}/menu-items/${create_UUID()}?policyNumber=${policyNumber}&smallProductCode=${smallProdCD}&screenType=${screenType}`;
    }

    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken: string) => {
        headers = new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken
        })
        return this.fetchMenuService.fetchMenu(url, this.httpClient, headers);
      }),
      catchError((err) => {
        this.loaderService.hide();
        console.error(err);
        this.layoutData.showSideNav = false;
        return of()
      })
    ).subscribe(leftMenu => {
      this.layoutData.showSideNav = true;
      this.layoutData.leftMenuItems = leftMenu;
      this.preCwsUserHomeNavigation(leftMenu);
    });
  }

  preCwsUserHomeNavigation(leftMenu:any){
    if(leftMenu.metadata && leftMenu.metadata.isStpPreCwsUser){
      const baseUrl = common_urls.cwsBaseUrl;
      const cwsHomeUrl = `${baseUrl}/MLJ_CWS_PreUser_Home?from=auth`;
      StpLayoutData.manulifeWordUrl = cwsHomeUrl;
      StpLayoutData.logoUrl = cwsHomeUrl;
    }
  }

  ngOnDestroy() {
    if (this.popupModalSubscribe) this.popupModalSubscribe.unsubscribe();
  }

  /**
   * Following method is related to navigate the application either internally to different route or to SFDC navigation
   * @param redirectURL : This value contains the redirection url
   * @param isInternalNavigation : This param defining if the redirect url is internal route or not 
   * @param queryParam : This param conatins the query param object for the redirection url
   */
  redirectToURL(redirectURL: string, isInternalNavigation: boolean | null = false, queryParam: { [key: string]: string } | null = null) {
    if (!isInternalNavigation)
      location.href = redirectURL;
    else
      if (queryParam)
        this.router.navigate([redirectURL], { queryParams: queryParam, relativeTo: this.route });
      else
        this.router.navigateByUrl(redirectURL);
  }
}
