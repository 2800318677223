<div *ngIf="!isCWSPage">
  <app-layout [layoutData]="layoutData" (menuItemClicked)="handleMenuClick($event)">
    <app-loader></app-loader>
    <main class="main-content-non-cws" main>
      <router-outlet></router-outlet>
    </main>
    <app-logout-button header *ngIf="showLogoutButton"></app-logout-button>
  </app-layout>
</div>

<div *ngIf="isCWSPage">
  <app-loader></app-loader>
  <app-cws-header></app-cws-header>
  <main class="main-content">
    <router-outlet></router-outlet>
  </main>
  <app-cws-footer></app-cws-footer>
</div>

<!--Start : Family name change popup html body -->
<ng-template #showFamilyNameMsg>
  <div class="family-name-msg-wrap">
    <div>{{'app.common-modal.contract-information-change.show-family-name-msg1' | translate}}</div>
    <div>{{'app.common-modal.contract-information-change.show-family-name-msg2' | translate}}</div>
    <div class="family-name-msg-wrap__msg3-wrap">
      <span class="material-icons family-name-msg-wrap__done-icon">done</span>
      {{'app.common-modal.contract-information-change.show-family-name-msg3-1' | translate}}
      <span class="red-text">{{'app.common-modal.contract-information-change.show-family-name-msg3-2' |
        translate}}</span>
      {{'app.common-modal.contract-information-change.show-family-name-msg3-2' | translate}}
    </div>
    <div>{{'app.common-modal.contract-information-change.show-family-name-msg4' | translate}}</div>
  </div>
</ng-template>
<!--End : Family name change popup html body -->

<!--Start : Address change popup html body -->
<ng-template #addressChange>
  <div>{{'app.common-modal.address-change.text-1' | translate}}</div>
  <div>{{'app.common-modal.address-change.text-2' | translate}}</div>
</ng-template>
<!--End : Address change popup html body -->

<!--Start : Show Error FAQ Modal popup html body -->
<ng-template #errorFaqModal>
  <div class="red-text">
    <div>{{ errorFaqModalMsgText | translate }}</div>
    <div>{{'app.common-modal.change-name.text-5' | translate}}</div>
    <div>{{'app.common-modal.change-name.text-6' | translate}}</div>
  </div>
</ng-template>
<!--End : Show Error FAQ Modal popup html body -->

<!--Start : Progress Modal popup html body -->
<ng-template #progressModal>
  <div>
    <div>{{'app.common-modal.cancellation.text-1' | translate}}</div>
    <div>{{'app.common-modal.cancellation.text-2' | translate}}</div>
    <div *ngIf="progressModalText">{{ progressModalText | translate }}</div>
  </div>
</ng-template>
<!--End : Progress Modal popup html body -->

<!--Start : Tax Certificate popup html body -->
<ng-template #taxCertificate>
  <div>{{'app.common-modal.taxCertificateIssue.text-1' | translate}}</div>
</ng-template>
<!--End : Tax Certificate popup html body -->