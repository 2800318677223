import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { MaterialModule } from './material/material.module';
import { ConfirmationModalComponent, CwsFooterComponent, CwsHeaderComponent, FooterComponent, HeaderComponent, InfoModalComponent, LoaderComponent, LogoutModalComponent, SurveyLogoutModalComponent, MessageModalComponent } from './shared';
import { translateConfig } from './translate-config';
import { AppLayoutComponent, FetchMenuService } from '@cws-ui/ngx-cws-app-layout';
import { LogoutButtonComponent } from './shared/logout-button/logout-button.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    LogoutButtonComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    SurveyLogoutModalComponent,
    LogoutModalComponent,
    InfoModalComponent,
    CwsHeaderComponent,
    CwsFooterComponent,
    ConfirmationModalComponent,
    MessageModalComponent
  ],
  imports: [
    BrowserModule,
    AuthConfigModule,
    NoopAnimationsModule,
    MaterialModule,
    AppLayoutComponent,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(translateConfig),
    BackButtonDisableModule.forRoot({
      preserveScroll: true
    }),
  ],
  providers: [FetchMenuService],
  bootstrap: [AppComponent]
})
export class AppModule { }
